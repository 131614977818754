(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-content/page-footer/views/footer.js') >= 0) return;  svs.modules.push('/components/tb-content/page-footer/views/footer.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.tb_content=_svs.tb_content||{};
_svs.tb_content.page_footer=_svs.tb_content.page_footer||{};
_svs.tb_content.page_footer.templates=_svs.tb_content.page_footer.templates||{};
svs.tb_content.page_footer.templates.footer = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selfService") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":176,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-tb_content-page_footer-footer_selfservice"),depth0,{"name":"components-tb_content-page_footer-footer_selfservice","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "    <footer class=\"page-footer bg-grey-700 padding-xs-v-2 padding-sm-v-4 animate animate-show\">\n      <div class=\"grid-row page-footer-max-width\">\n        <div class=\"col-xs-12 align-center padding-md-bottom-2\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page-footer") : depth0)) != null ? lookupProperty(stack1,"social") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":16,"column":22}}})) != null ? stack1 : "")
    + "</div>\n        <div class=\"grid-row gutter-xs-3 gutter-sm-4\">\n          <div class=\"col-sm-6\">\n            <div class=\"col-xs-12 col-sm-12 col-md-6 margin-xs-bottom-2\">\n              <div class=\"f-content f-content-inverted\">\n                <h2 class=\"h3\">Våra spel</h2>\n              </div>\n              <ul class=\"padding-xs-0 f-content f-content-inverted\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page-footer") : depth0)) != null ? lookupProperty(stack1,"products") : stack1),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":35,"column":25}}})) != null ? stack1 : "")
    + "              </ul>\n            </div>\n            <div class=\"col-xs-12 col-sm-12 col-md-6 margin-xs-bottom-2\">\n              <div class=\"f-content f-content-inverted\">\n                <h2 class=\"h3\">Kundservice</h2>\n              </div>\n              <ul class=\"padding-xs-0 f-content f-content-inverted\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page-footer") : depth0)) != null ? lookupProperty(stack1,"customerService") : stack1),{"name":"each","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":47,"column":25}}})) != null ? stack1 : "")
    + "              </ul>\n            </div>\n          </div>\n        <div class=\"col-sm-6\">\n          <div class=\"col-xs-12 col-sm-12 col-md-6 margin-xs-bottom-2\">\n            <div class=\"f-content f-content-inverted\">\n              <h2 class=\"h3\">Villkor och integritet</h2>\n            </div>\n            <ul class=\"padding-xs-0 f-content f-content-inverted\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page-footer") : depth0)) != null ? lookupProperty(stack1,"terms") : stack1),{"name":"each","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":14},"end":{"line":61,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n          </div>\n          <div class=\"col-xs-12 col-sm-12 col-md-6 margin-xs-bottom-2\">\n            <div class=\"f-content f-content-inverted\">\n              <h2 class=\"h3\">Mer från Svenska Spel</h2>\n            </div>\n            <ul class=\"padding-xs-0 f-content f-content-inverted\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page-footer") : depth0)) != null ? lookupProperty(stack1,"companyPages") : stack1),{"name":"each","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":14},"end":{"line":75,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page-footer") : depth0)) != null ? lookupProperty(stack1,"moreSvs") : stack1),{"name":"each","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":14},"end":{"line":82,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n          </div>\n        </div>\n      </div>\n      <div class=\"f-content f-content-inverted\">\n        <div class=\"col-xs-12 u-border-top-1 border-grey-600\"></div>\n          <div class=\"grid-row gutter-xs-3 gutter-sm-4\">\n            <div class=\"col-xs-12\">\n              <div class=\"col-xs-12 col-am-4 col-md-4 padding-xs-v-2 padding-sm-v-4 align-center\">\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"class":"footer-responsibility-icon","color":"fc-white","icon":"self-test","size":"800"},"data":data,"loc":{"start":{"line":92,"column":16},"end":{"line":92,"column":109}}}))
    + "<br/>\n                <h2 class=\"h4\">Testa dina spelvanor</h2>\n                <p>Gör det till en god vana att hålla koll på ditt spelande och gör ett självtest.</p>\n"
    + ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||alias2).call(alias1,{"name":"hasRole","hash":{"role":"Player"},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.program(31, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":95,"column":16},"end":{"line":103,"column":28}}})) != null ? stack1 : "")
    + "              </div>\n              <div class=\"col-xs-12 col-am-4 col-md-4 padding-xs-v-2 padding-sm-v-4 align-center\">\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"class":"footer-responsibility-icon","color":"fc-white","icon":"spelpaus","size":"800"},"data":data,"loc":{"start":{"line":106,"column":16},"end":{"line":106,"column":108}}}))
    + "<br/>\n                <h2 class=\"h4\">Vill du stänga av dig från spel?</h2>\n                <p>Här kan du stänga av dig från spel hos AB Svenska Spel eller hos alla spelbolag med spellicens i Sverige.</p>\n                <p><a class=\"u-text-reset\" href=\"/spelpaus\">Till Spelpaus</a></p>\n              </div>\n              <div class=\"col-xs-12 col-am-4 col-md-4 padding-xs-v-2 padding-sm-v-4 align-center\">\n                <div class=\"padding-bottom-stodlinjen\">\n                  <img src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/tb-content/page-footer/stodlinjen-neg.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":113,"column":28},"end":{"line":113,"column":95}}}))
    + "\" alt=\"Stödlinjen\" height=\"50\"/>\n                </div>\n                <h2 class=\"h4\">För spelare och anhöriga</h2>\n                <p>För anonym och kostnadsfri hjälp på uppdrag av Socialdepartementet. Telefon <a class=\"u-text-reset\" href=\"tel:020-819100\">020-81 91 00.</a></p>\n                <a class=\"u-text-reset\" href=\"https://www.stodlinjen.se\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNativeApp") || (depth0 != null ? lookupProperty(depth0,"isNativeApp") : depth0)) != null ? helper : alias2),(options={"name":"isNativeApp","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":117,"column":73},"end":{"line":117,"column":128}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNativeApp")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += " rel=\"nofollow noopener noreferrer\">Till Stödlinjen "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"icon":"external_link","size":"100","color":"white","class":"margin-xs-left"},"data":data,"loc":{"start":{"line":117,"column":180},"end":{"line":117,"column":262}}}))
    + "</a>\n              </div>\n            </div>\n          </div>\n          <div class=\"col-xs-12 u-border-top-1 border-grey-600\"></div>\n          <div class=\"grid-row gutter-xs-3 gutter-sm-4\">\n            <div class=\"col-sm-6\">\n              <div class=\"col-xs-12 col-sm-12 col-md-6 padding-xs-v-2 padding-sm-v-4 align-center\">\n                <a aria-label=\"Till svenskaspel.se/tur\" href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"startPage",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":125,"column":62},"end":{"line":125,"column":88}}}))
    + "\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNativeApp") || (depth0 != null ? lookupProperty(depth0,"isNativeApp") : depth0)) != null ? helper : alias2),(options={"name":"isNativeApp","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":125,"column":90},"end":{"line":125,"column":145}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNativeApp")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += " class=\"block margin-xs-bottom-1\">\n                "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":"SVENSKA SPEL","emblem":true,"brand":"svenskaspel-twolines-neg"},"data":data,"loc":{"start":{"line":126,"column":16},"end":{"line":126,"column":95}}}))
    + "\n                </a>\n                <p>\n                  AB Svenska Spel<br />\n                  Org.nummer: 556460-1812<br />\n                </p>\n                <p>Styrelsen säte: Visby</p>\n              </div>\n              <div class=\"col-xs-12 col-sm-12 col-md-6 padding-xs-v-2 padding-sm-v-4 align-center\">\n                <a aria-label=\"Till spelinspektionen.se\" href=\"http://spelinspektionen.se\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNativeApp") || (depth0 != null ? lookupProperty(depth0,"isNativeApp") : depth0)) != null ? helper : alias2),(options={"name":"isNativeApp","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":135,"column":91},"end":{"line":135,"column":146}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNativeApp")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += " rel=\"nofollow noopener noreferrer\" class=\"block margin-xs-bottom-1\">\n                <img src=\""
    + alias3((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||alias2).call(alias1,"/images/tb-content/page-footer/spelinspektionen.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":136,"column":26},"end":{"line":136,"column":95}}}))
    + "\" alt=\"Spelinspektionen\" />\n                </a>\n                <p>\n                  Spelinspektionen är licens- och tillsynsmyndighet.<br />\n                  Licenstid: 2019-01-01 - 2028-12-31.\n                </p>\n              </div>\n            </div>\n            <div class=\"col-sm-6\">\n              <div class=\"col-xs-12 col-sm-12 col-md-6 padding-xs-v-2 padding-sm-v-4 align-center\">\n                <a aria-label=\"Till om.svenskaspel.se/tillsammans-har-vi-spelkoll\" href=\"https://om.svenskaspel.se/tillsammans-har-vi-spelkoll/\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNativeApp") || (depth0 != null ? lookupProperty(depth0,"isNativeApp") : depth0)) != null ? helper : alias2),(options={"name":"isNativeApp","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":146,"column":145},"end":{"line":146,"column":200}}}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNativeApp")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " rel=\"nofollow noopener noreferrer\" class=\"block margin-xs-bottom-1\">\n                  <span class=\"footer-18-logo\">\n                    "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"symbols",{"name":"ui","hash":{"alt":"Spel för dig över 18 år","class":"align-center inline-block","name":"18-logo-neg"},"data":data,"loc":{"start":{"line":148,"column":20},"end":{"line":148,"column":119}}}))
    + "\n                  </span>\n                </a>\n                <p class=\"f-bold\">Spel för dig över 18 år</p>\n              </div>\n              <div class=\"col-xs-12 col-sm-12 col-md-6 padding-xs-v-2 padding-sm-v-4 align-center\">\n                <p>\n                  &copy; AB Svenska Spel<br>\n                  Denna webbplats är skyddad av upphovsrättslagen (1960:729).<br>\n                  Detta omfattar varumärken, text, fotografier, teckningar och bilder.\n                </p>\n              </div>\n            </div>\n          </div>\n          <div class=\"col-xs-12 u-border-top-1 border-grey-600\"></div>\n          <div class=\"col-xs-12 align-center bg-grey-700 padding-xs-bottom-4\">\n            <h2 class=\"h3\">Våra partners</h2>\n            <div class=\"grid-row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page-footer") : depth0)) != null ? lookupProperty(stack1,"partners") : stack1),{"name":"each","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":14},"end":{"line":170,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n      </div>\n    </footer>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a aria-label=\"Till Svenska Spels "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":57}}}) : helper)))
    + "\" class=\"padding-xs-h padding-sm-h-1\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"link") || (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data,"loc":{"start":{"line":9,"column":101},"end":{"line":9,"column":109}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"external") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":111},"end":{"line":9,"column":224}}})) != null ? stack1 : "")
    + " title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":9,"column":232},"end":{"line":9,"column":241}}}) : helper)))
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),"===","youtube",{"name":"compare","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":14,"column":28}}})) != null ? stack1 : "")
    + "              </a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isNativeApp") || (depth0 != null ? lookupProperty(depth0,"isNativeApp") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNativeApp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":127},"end":{"line":9,"column":182}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNativeApp")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " rel=\"nofollow noopener noreferrer\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "";
},"9":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\"";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"color":"fc-white","icon":(depth0 != null ? lookupProperty(depth0,"icon") : depth0),"size":"400","class":"margin-xs-left"},"data":data,"loc":{"start":{"line":11,"column":18},"end":{"line":11,"column":92}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"color":"fc-white","icon":(depth0 != null ? lookupProperty(depth0,"icon") : depth0),"size":"300","class":"margin-xs-left"},"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":13,"column":92}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ftName") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":33,"column":25}}})) != null ? stack1 : "")
    + "                  </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"feature")||(depth0 && lookupProperty(depth0,"feature"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ftName") : depth0),{"name":"feature","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":22},"end":{"line":30,"column":34}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"padding-xs-bottom u-text-reset\" href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"urlMappingName") : depth0),{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":29,"column":72},"end":{"line":29,"column":101}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"external") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":103},"end":{"line":29,"column":216}}})) != null ? stack1 : "")
    + " title=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"product") || (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"product","hash":{},"data":data,"loc":{"start":{"line":29,"column":224},"end":{"line":29,"column":235}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"product") || (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"product","hash":{},"data":data,"loc":{"start":{"line":29,"column":237},"end":{"line":29,"column":248}}}) : helper)))
    + "</a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a class=\"padding-xs-bottom u-text-reset\" href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"urlMappingName") : depth0),{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":32,"column":68},"end":{"line":32,"column":97}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"external") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":99},"end":{"line":32,"column":212}}})) != null ? stack1 : "")
    + " title=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"product") || (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"product","hash":{},"data":data,"loc":{"start":{"line":32,"column":220},"end":{"line":32,"column":231}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"product") || (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"product","hash":{},"data":data,"loc":{"start":{"line":32,"column":233},"end":{"line":32,"column":244}}}) : helper)))
    + "</a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li>\n                    <a class=\"padding-xs-bottom u-text-reset\" href="
    + alias4(((helper = (helper = lookupProperty(helpers,"link") || (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data,"loc":{"start":{"line":45,"column":67},"end":{"line":45,"column":75}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"external") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":75},"end":{"line":45,"column":188}}})) != null ? stack1 : "")
    + " title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":45,"column":196},"end":{"line":45,"column":205}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":45,"column":207},"end":{"line":45,"column":216}}}) : helper)))
    + "</a>\n                  </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\n                  <a class=\"u-text-reset\" href="
    + alias4(((helper = (helper = lookupProperty(helpers,"link") || (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data,"loc":{"start":{"line":59,"column":47},"end":{"line":59,"column":55}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onClick") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":56},"end":{"line":59,"column":99}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"external") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":100},"end":{"line":59,"column":213}}})) != null ? stack1 : "")
    + " title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":59,"column":221},"end":{"line":59,"column":230}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":59,"column":232},"end":{"line":59,"column":241}}}) : helper)))
    + "</a>\n                </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "onClick=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"onClick") || (depth0 != null ? lookupProperty(depth0,"onClick") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"onClick","hash":{},"data":data,"loc":{"start":{"line":59,"column":80},"end":{"line":59,"column":91}}}) : helper)))
    + "\"";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\n                  <a class=\"u-text-reset\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"link") || (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data,"loc":{"start":{"line":71,"column":48},"end":{"line":71,"column":56}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"external") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":58},"end":{"line":71,"column":171}}})) != null ? stack1 : "")
    + " title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":71,"column":179},"end":{"line":71,"column":188}}}) : helper)))
    + "\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":72,"column":20},"end":{"line":72,"column":29}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"external") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":30},"end":{"line":72,"column":135}}})) != null ? stack1 : "")
    + "\n                  </a>\n                </li>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"icon":"external_link","size":"100","color":"white","class":"margin-xs-left"},"data":data,"loc":{"start":{"line":72,"column":46},"end":{"line":72,"column":128}}}));
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"u-text-reset\" href=\""
    + container.escapeExpression((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"gamingResponsibilityHabits",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":96,"column":46},"end":{"line":96,"column":89}}}))
    + "\">\n                Till Självtest\n                </a>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                <a class=\"u-text-reset\" href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"gamingResponsibilitySelfTest",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":100,"column":46},"end":{"line":100,"column":91}}}))
    + "\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNativeApp") || (depth0 != null ? lookupProperty(depth0,"isNativeApp") : depth0)) != null ? helper : alias2),(options={"name":"isNativeApp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":100,"column":93},"end":{"line":100,"column":148}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNativeApp")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " rel=\"nofollow noopener noreferrer\">\n                Till Självtest"
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"icon":"external_link","size":"100","color":"white","class":"margin-xs-left"},"data":data,"loc":{"start":{"line":101,"column":30},"end":{"line":101,"column":112}}}))
    + "\n                </a>\n";
},"33":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\""
    + alias3((lookupProperty(helpers,"partnerColClasses")||(depth0 && lookupProperty(depth0,"partnerColClasses"))||alias2).call(alias1,(data && lookupProperty(data,"index")),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"page-footer") : depths[1])) != null ? lookupProperty(stack1,"partners") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"partnerColClasses","hash":{},"data":data,"loc":{"start":{"line":167,"column":28},"end":{"line":167,"column":87}}}))
    + " margin-xs-top-2\">\n                  "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"iconType") : depth0),{"name":"ui","hash":{"size":(depth0 != null ? lookupProperty(depth0,"size") : depth0),"alt":(depth0 != null ? lookupProperty(depth0,"partner") : depth0),"title":(depth0 != null ? lookupProperty(depth0,"partner") : depth0),"name":(depth0 != null ? lookupProperty(depth0,"icon") : depth0)},"data":data,"loc":{"start":{"line":168,"column":18},"end":{"line":168,"column":79}}}))
    + "\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":177,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-tb_content-page_footer-footer'] = svs.tb_content.page_footer.templates.footer;
})(svs, Handlebars);


 })(window);